<!--
 * @Author: luxiangqiang
 * @Date: 2023-05-04 09:43:02
 * @LastEditors: luxiangqiang
 * @LastEditTime: 2023-05-12 11:10:44
-->
<template>
  <div class="hot-list">
    <div class="title" @click="handlerGoCountryList">
      <img src="@/assets/icons/hot.svg" alt="hot" />
      <span class="title_text">{{ $t('出海国家热门榜') }}</span>
      <img class="arrow" src="@/assets/icons/arrow2-blue.svg" alt="arrow" />
    </div>
    <div class="tabs" @mouseenter="stopAutoPlay" @mouseleave="startAutoPlay">
      <div class="tabs_bar">
        <div
          class="tab"
          :class="{ active: tab === item.tab }"
          v-for="item of tabs" :key="item.tab"
          @click="handlerOnTabClick(item.tab)"
          ref="elTabList"
        >
          {{ $t(item.tab) }}
        </div>
      </div>
      <div>
        <div class="tabs-wrapper" :class="{ active: tab === item.tab }" v-for="item of tabs" :key="item.tab"
          v-show="tab === item.tab" @click="handlerGoCountryList">
          <div class="tabs_content">
            <div class="name-content">
              <div class="name-item" v-for="el of item.countries" :key="el.name">{{ $t(el.name) }}</div>
            </div>
            <div class="bar-content">
              <div class="bar-item" v-for="el of item.countries" :key="el.name">
                <span class="bar">
                  <span class="fill" :style="{ width: el.num + '%' }"></span>
                </span>
              </div>
            </div>
            <div class="count-content">
              <div class="count-item" v-for="el of item.countries" :key="el.name">{{ el.count }}</div>
            </div>
          </div>
          <div class="bottom-text">{{ $t('数据代表') }}：{{ $t('热度值') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { tabs } from './config';

const router = useRouter();
const tab = ref('亚洲');

onMounted(() => {
  startAutoPlay();
});

const autoPlayTimer = ref(0);

function stopAutoPlay() {
  window.clearTimeout(autoPlayTimer.value);
  autoPlayTimer.value = 0;
}

const elTabList = ref();
const currentBeforeWidth = ref('0px');
const currentAfterLeft = ref('64px');

function startAutoPlay() {
  if (autoPlayTimer.value) {
    return;
  }
  autoPlayTimer.value = window.setTimeout(() => {
    stopAutoPlay();
    const currentTabIndex = tabs.findIndex(conf => conf.tab === tab.value);
    if (currentTabIndex === tabs.length - 1) {
      tab.value = tabs[0].tab;
    } else {
      tab.value = tabs[currentTabIndex + 1].tab;
    }
    calcTabBorder();
    startAutoPlay();
  }, 2000);
}

function calcTabBorder() {
  const index = tabs.findIndex(t => t.tab === tab.value);
  if (index < 0) {
    return;
  }
  const tabEl = elTabList.value[index];
  if (!tabEl) {
    return;
  }
  currentBeforeWidth.value = `${tabEl.offsetLeft}px`;
  currentAfterLeft.value = `${tabEl.clientWidth + tabEl.offsetLeft}px`;
}

const handlerOnTabClick = (value: string | number) => {
  tab.value = value.toString();
  calcTabBorder();
};

const handlerGoCountryList = () => {
  router.push(`/rank-list`);
};
</script>

<style scoped lang="less">
@media screen and (min-width: 800px) {
  .hot-list {
    .title {
      cursor: pointer;
      display: flex;
      align-items: center;

      img {
        width: 25px;
        height: 25px;
        margin-right: 8px;
      }

      .arrow {
        width: 20px;
        height: 20px;
      }

      &_text {
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        color: #1d2129;
        margin-right: 3px;
      }
    }

    .tabs {
      width: 455px;
      margin-top: 15px;
      .tabs-wrapper {
        cursor: pointer;
        background: rgba(241, 245, 255, 0.5);
        backdrop-filter: blur(6px);
        padding-bottom: 8px;
        padding-top: 20px;
        border-radius: 8px;
        border: 2px solid #fff;
        .tabs_content {
          // background: linear-gradient(38.81deg, rgba(226, 248, 255, 0.62) 18.28%, rgba(241, 245, 255, 0.74) 62.97%);
          display: flex;

          @media screen and (max-width: 800px) {
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
          }
          .name-content {
            display: flex;
            flex-direction: column;
            gap: 12px;
            .name-item {
              margin: 0 18px;
              font-weight: 400;
              font-size: 18px;
              line-height: 25px;
              text-align: right;
              color: #415C83;
              white-space: nowrap;
            }
          }
          .bar-content {
            display: flex;
            flex-direction: column;
            gap: 12px;
            flex: 1;
            .bar-item {
              width: 100%;
              height: 25px;
              display: flex;
              align-items: center;
              .bar {
                width: 100%;
                position: relative;
                display: inline-block;
                height: 6px;
                background-color: rgba(252, 254, 255, 0.9);
                box-shadow: 0px 1px 8px rgba(42, 92, 179, 0.2);
                border-radius: 3px;

                .fill {
                  height: 6px;
                  display: inline-block;
                  position: absolute;
                  background: #5286ff;
                  border-radius: 3px;
                }
              }
            }
          }
          .count-content {
            display: flex;
            flex-direction: column;
            gap: 12px;
            .count-item {
              margin: 0 20px;
              line-height: 25px;
              text-align: right;
              color: #415C83;
              white-space: nowrap;
              font-weight: 700;
              font-size: 16px;
              color: #1D2129;
            }
          }
        }
      }

      .tabs-wrapper.active {
        border-top: 0;
        position: relative;
        overflow: hidden;
        &::before, &::after {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 2px;
          background: #fff;
        }
        &::before {
          left: 0;
          width: v-bind(currentBeforeWidth);
        }
        &::after {
          left: v-bind(currentAfterLeft);
          right: 0;
        }
        &:first-child {
          border-top-left-radius: 0;
          &::before {
            display: none;
          }
        }
        &:last-child {
          border-top-right-radius: 0;
          &::after {
            display: none;
          }
        }
      }

      &_bar {
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 1;
      }

      .tab {
        background: rgba(241, 245, 255, 0.5);
        backdrop-filter: blur(6px);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 36px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        color: #415c83;
        padding: 4px 16px;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        border: 2px solid #fff;
      }

      .tab.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        height: 45px;
        border-bottom: 0;
        color: #386aff;
        font-weight: 600;

        @media screen and (max-width: 800px) {
          box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
        }
      }
      .bottom-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #3b67a8;
      }
    }
  }
}
</style>

<style scoped lang="less">
@media screen and (max-width: 800px) {
  .hot-list {
    width: 100%;
    overflow: hidden;
    padding: 0 20px 20px 20px;

    .title {
      cursor: pointer;
      display: flex;
      align-items: center;

      img {
        width: 25px;
        height: 25px;
        margin-right: 8px;
      }

      .arrow {
        width: 20px;
        height: 20px;
      }

      &_text {
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        color: #1d2129;
        margin-right: 3px;
      }
    }

    .tabs {
      margin-top: 15px;
      .tabs-wrapper {

        background: linear-gradient(180.78deg, #E2F1FF 1.27%, #F4F9FF 42.6%, #FFFFFF 102.99%);
        cursor: pointer;
        backdrop-filter: blur(6px);
        padding-bottom: 8px;
        padding-top: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
        border: 2px solid #fff;
        .tabs_content {
          display: flex;
          .name-content {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .name-item {
              margin: 0 12px;
              font-weight: 400;
              font-size: 14px;
              line-height: 25px;
              text-align: right;
              color: #415C83;
              white-space: nowrap;
            }
          }
          .bar-content {
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex: 1;
            .bar-item {
              width: 100%;
              height: 25px;
              display: flex;
              align-items: center;
              .bar {
                width: 100%;
                position: relative;
                display: inline-block;
                height: 6px;
                background-color: rgba(252, 254, 255, 0.9);
                box-shadow: 0px 1px 8px rgba(42, 92, 179, 0.2);
                border-radius: 3px;

                .fill {
                  height: 6px;
                  display: inline-block;
                  position: absolute;
                  background: #5286ff;
                  border-radius: 3px;
                }
              }
            }
          }
          .count-content {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .count-item {
              margin: 0 20px;
              line-height: 25px;
              text-align: right;
              color: #415C83;
              white-space: nowrap;
              font-weight: 700;
              font-size: 14px;
              color: #1D2129;
            }
          }
        }
      }

      .tabs-wrapper.active {
        margin-top: -2px;
        &:first-child {
          border-top-left-radius: 0;
        }

        &:last-child {
          border-top-right-radius: 0;
        }
      }

      &_bar {
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 1;
        gap: 4px;
      }

      .tab {
        backdrop-filter: blur(6px);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        height: 32px;
        border-radius: 8px;
        font-size: 14px;
        line-height: 28px;
        color: #415c83;
        padding-top: 2px;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
        background: #FFFFFF;
        border: 1px solid #E5E7EB;
        border-radius: 8px;
        flex-grow: 1;
        -webkit-tap-highlight-color: transparent;
      }

      .tab.active {
        background: #E2F1FF;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        color: #386aff;
        font-weight: 600;
        border-bottom: 0;
        border: 2px solid #fff;
        border-bottom: 0;
        box-shadow: 0px -10px 16px -5px rgba(0, 0, 0, 0.08);
        height: 38px;
      }

      .country-item {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        .name {
          text-align: right;
          line-height: 25px;
          text-align: right;
          color: #415c83;
          margin-right: 10px;
          width: 90px;
          font-size: 16px;
        }

        .bar {
          position: relative;
          display: inline-block;
          width: 170px;
          height: 6px;
          background-color: rgba(252, 254, 255, 0.9);
          box-shadow: 0px 1px 8px rgba(42, 92, 179, 0.2);
          border-radius: 3px;

          .fill {
            height: 6px;
            display: inline-block;
            position: absolute;
            background: #5286ff;
            border-radius: 3px;
          }
        }

        .count {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #1d2129;
          margin-left: 15px;
        }
      }

      .bottom-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #3b67a8;
      }
    }
  }
}
</style>
