export const tabs = [
  {
    tab: '亚洲',
    countries: [
      {
        name: '越南',
        count: '28294',
        num: 94.31
      },
      {
        name: '印尼',
        count: '26606',
        num: 88.69
      },
      {
        name: '泰国',
        count: '25194',
        num: 83.98
      },
      {
        name: '日本',
        count: '24699',
        num: 82.33
      },
      {
        name: '菲律宾',
        count: '22740',
        num: 75.8
      }
    ]
  },
  {
    tab: '欧洲',
    countries: [
      {
        name: '德国',
        count: '14427',
        num: 48.09
      },
      {
        name: '英国',
        count: '12728',
        num: 42.43
      },
      {
        name: '法国',
        count: '10036',
        num: 33.45
      },
      {
        name: '塞尔维亚',
        count: '7636',
        num: 25.45
      },
      {
        name: '波兰',
        count: '7525',
        num: 25.08
      }
    ]
  },
  {
    tab: '欧亚',
    countries: [
      {
        name: '俄罗斯',
        count: '20351',
        num: 67.84
      },
      {
        name: '哈萨克斯坦',
        count: '14074',
        num: 46.91
      },
      {
        name: '乌兹别克斯坦',
        count: '11478',
        num: 38.26
      },
      {
        name: '乌克兰',
        count: '7253',
        num: 24.18
      },
      {
        name: '吉尔吉斯斯坦',
        count: '7618',
        num: 25.39
      }
    ]
  },
  {
    tab: '美洲/大洋洲',
    countries: [
      {
        name: '美国',
        count: '20765',
        num: 69.22
      },
      {
        name: '巴西',
        count: '13716',
        num: 45.72
      },
      {
        name: '澳大利亚',
        count: '12805',
        num: 42.68
      },
      {
        name: '阿根廷',
        count: '10484',
        num: 34.95
      },
      {
        name: '墨西哥',
        count: '9388',
        num: 31.29
      }
    ]
  },
  {
    tab: '中东/非洲',
    countries: [
      {
        name: '埃及',
        count: '15108',
        num: 50.36
      },
      {
        name: '阿联酋',
        count: '13885',
        num: 46.28
      },
      {
        name: '尼日利亚',
        count: '12954',
        num: 43.18
      },
      {
        name: '沙特阿拉伯',
        count: '12947',
        num: 43.16
      },
      {
        name: '阿尔及利亚',
        count: '12232',
        num: 40.77
      }
    ]
  }
];
